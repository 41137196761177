<template>
  <BaaSApplication />
</template>

<script>
import { BaaSApplication } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSApplication,
  },
};
</script>
